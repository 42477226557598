import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { enUS, it } from "date-fns/locale"; 
import "react-datepicker/dist/react-datepicker.css";
import { startOfWeek, endOfWeek, eachDayOfInterval } from 'date-fns';

function WeekSelector({ setReferenceDate: setAppReferenceDate }) {
  const { i18n } = useTranslation();
  const [localReferenceDate, setLocalReferenceDate] = useState(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const updateWeek = useCallback(
    (date) => {
      const startOfWeek = new Date(date);
      startOfWeek.setDate(date.getDate() - date.getDay() + 1);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      const options = { month: "long", day: "2-digit" };

      return {
        start: startOfWeek.toLocaleDateString(i18n.language, options),
        end: endOfWeek.toLocaleDateString(i18n.language, options),
        year: startOfWeek.getFullYear(),
      };
    },
    [i18n.language]
  );

  useEffect(() => {
    updateWeek(localReferenceDate);
    setAppReferenceDate(localReferenceDate);
  }, [localReferenceDate, setAppReferenceDate, updateWeek]);

  const handlePreviousWeek = () => {
    const previousWeek = new Date(localReferenceDate);
    previousWeek.setDate(localReferenceDate.getDate() - 7);
    setLocalReferenceDate(previousWeek);
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(localReferenceDate);
    nextWeek.setDate(localReferenceDate.getDate() + 7);
    setLocalReferenceDate(nextWeek);
  };

  const handleDateSelect = (date) => {
    setLocalReferenceDate(date);
    setIsDatePickerOpen(false);
  };

  const handleCalendarToggle = () => {
    setIsDatePickerOpen((prevState) => !prevState); 
  };

  const getLocale = () => {
    switch (i18n.language) {
      case "it":
        return it;
      default:
        return enUS;
    }
  };

  const currentWeek = updateWeek(localReferenceDate);

  const highlightWeek = (date) => {
    if (!date) return [];
    const start = startOfWeek(date, { weekStartsOn: 1 }); 
    const end = endOfWeek(date, { weekStartsOn: 1 });
    return eachDayOfInterval({ start, end });
  };

  return (
    <div className="text-center m-1 position-relative">
      <button className="btn p-0" onClick={handlePreviousWeek}>
        <FontAwesomeIcon icon={faChevronLeft} className="arrow-icon" />
      </button>
      <span className="mx-2" onClick={handleCalendarToggle} style={{ cursor: "pointer" }}>
        {currentWeek.start} - {currentWeek.end}, {currentWeek.year}
        <FontAwesomeIcon icon={faCalendarAlt} className="ml-2" />
      </span>
      <button className="btn p-0" onClick={handleNextWeek}>
        <FontAwesomeIcon icon={faChevronRight} className="arrow-icon" />
      </button>

      {isDatePickerOpen && (
        <div className="position-absolute datepicker-overlay">
          <DatePicker
            selected={localReferenceDate}
            onChange={handleDateSelect}
            inline
            dateFormat="dd/MM/yyyy"
            shouldCloseOnSelect={true}  
            locale={getLocale()}
            highlightDates={highlightWeek(localReferenceDate)}            
          />
        </div>
      )}
    </div>
  );
}

export default WeekSelector;
