import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/en.json';
import translationIT from './locales/it.json';

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
};

const savedLanguage = localStorage.getItem('i18nextLng') || 'it';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage, 
    fallbackLng: "en",  
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
