import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ModalConfirmation({ show, onClose, onConfirm, title, message }) {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="green-color">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <div className="d-flex justify-content-end mt-4">
          <Button className="green-text-button fw-semibold me-2" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            className="green-background text-white fw-semibold"
            onClick={onConfirm}
          >
            {t("yes")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalConfirmation;
