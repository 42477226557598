import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../AuthContext";

const Option = (props) => (
  <components.Option {...props}>
    <div className="d-flex align-items-center">
      <img
        src={`${process.env.PUBLIC_URL}/${props.data.icon}.svg`}
        alt="Icon"
        style={{ width: "1rem" }}
        className="mr-2"
      />
      <span>{props.data.label}</span>
    </div>
  </components.Option>
);

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <div className="d-flex align-items-center">
      <img
        src={`${process.env.PUBLIC_URL}/${props.data.icon}.svg`}
        alt="Icon"
        style={{ width: "1rem" }}
        className="mr-2"
      />
      <span>{props.data.label}</span>
    </div>
  </components.SingleValue>
);

function ModalLeavesType({ show, handleClose, leave, onUpdate }) {

  const { token, updateToken } = useAuth();
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState(
    leave.leaveType ? {
      value: leave.leaveType.value ? leave.leaveType.value : leave.leaveType.id,
      label: leave.leaveType.label ? leave.leaveType.label : leave.leaveType.name,
      icon: leave.leaveType.icon,
    } : null
  );
  const { t } = useTranslation();
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue('--main-color').trim();

  useEffect(() => {
    const fetchLeaveTypes = async () => {
      await updateToken(); 
      if (token) {
        timeslotsApi.getLeaveTypes(token)
          .then((response) => {
            const formattedTypes = response.data.map((type) => ({
              value: type.id,
              label: type.name,
              icon: type.icon,
            }));
            setLeaveTypes(formattedTypes);
          })
          .catch((error) => {
            console.error("Error while loading leave types:", error);
          });
      }
    };
    
    fetchLeaveTypes();
  }, [token, updateToken]);

  const handleSaveLeaveType = async () => {
    const updatedLeave = {
      ...leave,
      leaveTypePk: selectedLeaveType ? selectedLeaveType.value : null,
    };
    try {
      await updateToken();
      if (token) {
        await timeslotsApi.updateLeave(leave.id, updatedLeave, token);
        onUpdate({ ...updatedLeave, leaveType: selectedLeaveType });
        toast.success(t('leaveSaved'));
        handleClose();
      }
    } catch (error) {
      toast.error(t('leaveError'));
      console.error("Error while saving leave type:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="green-color">{t('leaveType')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formLeaveType">
            <Form.Label className="green-color">{t('type')}</Form.Label>
            <Select
              classNamePrefix="custom-select"
              value={selectedLeaveType}
              onChange={setSelectedLeaveType}
              options={leaveTypes}
              components={{ Option, SingleValue }}
              placeholder={t('typePlaceholder')}
              noOptionsMessage={() => t('typesNoOptions')}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: '#dee2e6',
                },
              })}
            />
            <div className="d-flex justify-content-end mt-2">
              <Button
                className="green-background text-white fw-semibold"
                onClick={handleSaveLeaveType}
              >
                {t('save')}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalLeavesType;
