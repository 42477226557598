import React from "react";
import { ThreeDot } from "react-loading-indicators";

const Loading = () => {
  return (
    <div className="loading">
      <ThreeDot variant="brick-stack" color="#209188" size="large" text="" textColor="" />
    </div>
  );
};

export default Loading;
