import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Table, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircle,
  faMagnifyingGlass,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import Select, { components } from "react-select";
import { useAuth } from "../AuthContext";
import { timeslotsApi } from "../misc/TimeslotsApi";

function TimesheetApproval() {
  const { i18n, t } = useTranslation();
  const mainColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--main-color")
    .trim();
  const months = [
    { value: 1, label: t("months.January") },
    { value: 2, label: t("months.February") },
    { value: 3, label: t("months.March") },
    { value: 4, label: t("months.April") },
    { value: 5, label: t("months.May") },
    { value: 6, label: t("months.June") },
    { value: 7, label: t("months.July") },
    { value: 8, label: t("months.August") },
    { value: 9, label: t("months.September") },
    { value: 10, label: t("months.October") },
    { value: 11, label: t("months.November") },
    { value: 12, label: t("months.December") },
  ];
  const { token, updateToken } = useAuth();
  const [timesheetWorkflows, setTimesheetWorkflows] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const currentMonth = new Date().getMonth() + 1;
    return months.find((month) => month.value === currentMonth);
  });
  const [expandedRows, setExpandedRows] = useState([]);
  const [orderByStatus, setOrderByStatus] = useState(false);
  const [orderByDate, setOrderByDate] = useState("asc");
  const [orderByUser, setOrderByUser] = useState("asc");
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);

  const selectMonthPlaceholder = (props) => {
    return (
      <components.Placeholder {...props}>
        <div className="d-flex align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/calendar1.svg`}
            alt="Select a month"
            style={{
              width: "1rem",
            }}
            className="mr-2"
          />
          {t("selectMonth")}
        </div>
      </components.Placeholder>
    );
  };

  const toggleRow = (resourceId) => {
    setExpandedRows((prev) =>
      prev.includes(resourceId)
        ? prev.filter((id) => id !== resourceId)
        : [...prev, resourceId]
    );
  };

  const toggleSelectAll = () => {
    if (isSelectAll) {
      const allWorkflowIds = timesheetWorkflows.map((workflow) => workflow.id);
      setSelectedWorkflows(allWorkflowIds);
    } else {
      setSelectedWorkflows([]);
    }
    setIsSelectAll((prev) => !prev);
  };

  const toggleSelectWorkflow = (workflowId) => {
    setSelectedWorkflows((prev) =>
      prev.includes(workflowId)
        ? prev.filter((id) => id !== workflowId)
        : [...prev, workflowId]
    );
  };

  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "short",
    };
    const date = new Date(dateString);
    return date
      .toLocaleDateString(i18n.language, options)
      .toUpperCase()
      .replace(".", "");
  };

  const getStatusDetails = (statusName) => {
    switch (statusName) {
      case "pending":
        return { color: "grey", label: t("workflowStatuses.pending") };
      case "approved":
        return { color: "seagreen", label: t("workflowStatuses.approved") };
      case "rejected":
        return { color: "red", label: t("workflowStatuses.rejected") };
      case "in_compilation":
        return { color: "orange", label: t("workflowStatuses.inCompilation") };
      default:
        return { color: "black", label: t("workflowStatuses.unknown") };
    }
  };

  const groupedWorkflows = timesheetWorkflows.reduce((acc, workflow) => {
    const resourceId = workflow.resource.id;
    if (!acc[resourceId]) {
      acc[resourceId] = {
        resource: workflow.resource,
        workflows: [],
      };
    }
    acc[resourceId].workflows.push(workflow);
    return acc;
  }, {});

  const sortedGroupedWorkflows = Object.values(groupedWorkflows).sort(
    (a, b) => {
      const fullNameA = `${a.resource.name} ${a.resource.surname}`;
      const fullNameB = `${b.resource.name} ${b.resource.surname}`;
      return orderByUser === "asc"
        ? fullNameA.localeCompare(fullNameB)
        : fullNameB.localeCompare(fullNameA);
    }
  );

  const fetchTimesheetWorkflows = useCallback(
    async (selectedMonth) => {
      if (!selectedMonth) return;
      const currentYear = new Date().getFullYear();

      const startDate = new Date(currentYear, selectedMonth.value - 1, 1);
      const endDate = new Date(currentYear, selectedMonth.value, 0);

      const dateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      const formattedStartDate = startDate
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-");
      const formattedEndDate = endDate
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-");

      try {
        await updateToken();
        const timesheetWorkflowsData =
          await timeslotsApi.getAllWorkflowsByApprover(
            formattedStartDate,
            formattedEndDate,
            token
          );
        setTimesheetWorkflows(timesheetWorkflowsData?.data || []);
      } catch (error) {
        console.error("Error while loading timesheet workflows:", error);
        setTimesheetWorkflows([]);
        if (error.response?.status === 401) {
          console.log("Token might be expired. Attempting to update token...");
          await updateToken();
          fetchTimesheetWorkflows(selectedMonth);
        } else {
          console.error("Error while loading timesheet workflows:", error);
          setTimesheetWorkflows([]);
        }
      }
    },
    [token, updateToken]
  );

  useEffect(() => {
    fetchTimesheetWorkflows(selectedMonth);
  }, [selectedMonth, fetchTimesheetWorkflows]);

  return (
    <div className="row">
      <div className="col-6 text-start">
        <h4 className="fw-semibold">{t("timesheetApproval")} </h4>
      </div>
      <div className="row">
        <div className="col-12 text-start">
          <Button className="green-text-button fw-semibold">
            <FontAwesomeIcon icon={faCircle} color="grey" className="mr-1" />
            {t("reopen")}
          </Button>
          <Button className="green-text-button fw-semibold ml-3">
            <FontAwesomeIcon
              icon={faCircle}
              color="seagreen"
              className="mr-1"
            />
            {t("approve")}
          </Button>
          <Button className="green-text-button fw-semibold ml-3">
            <FontAwesomeIcon icon={faCircle} color="red" className="mr-1" />
            {t("reject")}
          </Button>
          <Table hover>
            <thead>
              <tr>
                <th className="px-0">
                  <div className="row">
                    <div className="col-2">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder={t("search")}
                          className="custom-form-control"
                        />
                        <InputGroup.Text
                          id="search"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="green-color"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-2">
                      <Button
                        className="white-text-button fw-semibold mr-2 w-100"
                        onClick={() => {
                          setOrderByUser((prev) =>
                            prev === "asc"
                              ? "desc"
                              : prev === "desc"
                              ? "asc"
                              : "asc"
                          );
                          setOrderByDate(null);
                          setOrderByStatus(null);
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/user.svg`}
                          alt="Order by users"
                          style={{
                            width: "1rem",
                          }}
                          className="mr-2 mb-1"
                        />
                        {t("orderByUsers")}
                        <FontAwesomeIcon
                          icon={faSort}
                          className="ms-2"
                          color="white"
                        />
                      </Button>
                    </div>
                    <div className="col-2">
                      <Button
                        className="white-text-button fw-semibold w-100"
                        onClick={() => {
                          setOrderByStatus((prev) =>
                            prev === "asc"
                              ? "desc"
                              : prev === "desc"
                              ? "asc"
                              : "asc"
                          );
                          setOrderByDate(null);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-2"
                          color="white"
                        />
                        {t("orderByStatus")}
                        <FontAwesomeIcon
                          icon={faSort}
                          className="ms-2"
                          color="white"
                        />
                      </Button>
                    </div>
                    <div className="col-2">
                      <Button
                        className="white-text-button fw-semibold w-100"
                        onClick={() => {
                          setOrderByDate((prev) =>
                            prev === "asc"
                              ? "desc"
                              : prev === "desc"
                              ? "asc"
                              : "asc"
                          );
                          setOrderByStatus(null);
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/calendar3.svg`}
                          alt="Order by date"
                          style={{
                            width: "1rem",
                          }}
                          className="mr-2 mb-1"
                        />
                        {t("orderByDate")}
                        <FontAwesomeIcon
                          icon={faSort}
                          className="ms-2"
                          color="white"
                        />
                      </Button>
                    </div>
                    <div className="col-2">
                      <Select
                        classNamePrefix="custom-select"
                        components={{
                          Placeholder: selectMonthPlaceholder,
                        }}
                        isSearchable={false}
                        options={months}
                        value={selectedMonth}
                        onChange={setSelectedMonth}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: "#dee2e6",
                          },
                        })}
                      />
                    </div>
                    <div className="col-2">
                      <Button
                        className="white-text-button fw-semibold w-100"
                        onClick={toggleSelectAll}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/${
                            isSelectAll ? "selectAll" : "deselectAll"
                          }.svg`}
                          alt={isSelectAll ? t("selectAll") : t("deselectAll")}
                          style={{
                            width: "1rem",
                          }}
                          className="mr-2 mb-1"
                        />
                        {t(isSelectAll ? "selectAll" : "deselectAll")}
                      </Button>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedGroupedWorkflows.map(({ resource, workflows }) => (
                <React.Fragment key={resource.id}>
                  <tr onClick={() => toggleRow(resource.id)}>
                    <td className="text-start fw-semibold">
                      <h5 className="m-0">
                        <img
                          src={
                            expandedRows.includes(resource.id)
                              ? `${process.env.PUBLIC_URL}/contract.svg`
                              : `${process.env.PUBLIC_URL}/expand.svg`
                          }
                          alt={
                            expandedRows.includes(resource.id)
                              ? "Collapse"
                              : "Expand"
                          }
                          style={{
                            width: "1rem",
                          }}
                          className="mr-2 mb-1"
                        />
                        {resource.name} {resource.surname}
                      </h5>
                    </td>
                  </tr>
                  {expandedRows.includes(resource.id) && (
                    <tr>
                      <td className="m-0 p-0">
                        <Table hover className="mb-0 pb-0">
                          <tbody>
                            {workflows
                              .slice()
                              .sort((a, b) => {
                                if (orderByDate) {
                                  return orderByDate === "asc"
                                    ? new Date(a.startDate) -
                                        new Date(b.startDate)
                                    : new Date(b.startDate) -
                                        new Date(a.startDate);
                                } else if (orderByStatus) {
                                  return orderByStatus === "asc"
                                    ? (
                                        a.timesheetStatus?.name || ""
                                      ).localeCompare(
                                        b.timesheetStatus?.name || ""
                                      )
                                    : (
                                        b.timesheetStatus?.name || ""
                                      ).localeCompare(
                                        a.timesheetStatus?.name || ""
                                      );
                                }
                                return 0;
                              })
                              .map((workflow) => {
                                const { color, label } = getStatusDetails(
                                  workflow.timesheetStatus?.name
                                );
                                return (
                                  <tr key={workflow.id} className="text-center">
                                    <td className="light-green-background">
                                      {`${formatDate(
                                        workflow.startDate
                                      )} - ${formatDate(
                                        workflow.endDate
                                      )} ${new Date(
                                        workflow.endDate
                                      ).getFullYear()}`}
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <FontAwesomeIcon
                                          icon={faCircle}
                                          color={color}
                                          className="mr-2"
                                          size="xs"
                                        />
                                        <span className="status-label">
                                          {label}
                                        </span>
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td className="light-green-background">
                                      <Form.Check
                                        type="checkbox"
                                        id="selectRowCheckbox"
                                        className="custom-switch"
                                        checked={selectedWorkflows.includes(
                                          workflow.id
                                        )}
                                        onChange={() =>
                                          toggleSelectWorkflow(workflow.id)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default TimesheetApproval;
