import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import DatePicker from "react-datepicker";
import { enUS, it } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../../AuthContext";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";

function ModalCreateResource({ show, handleClose }) {
  const { token, updateToken } = useAuth();
  const { t } = useTranslation();
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue("--main-color").trim();
  const { i18n } = useTranslation();
  const [fiscalCode, setFiscalCode] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [isLegalEntity, setIsLegalEntity] = useState(false);
  const [externalCode, setExternalCode] = useState("");
  const [resourceActive, setResourceActive] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [selectedGender, setSelectedGender] = useState(null);
  const [title, setTitle] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [birthProvince, setBirthProvince] = useState("");
  const [birthCity, setBirthCity] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [cityOfResidence, setCityOfResidence] = useState("");
  const [postalCodePerson, setPostalCodePerson] = useState("");
  const [countryPerson, setCountryPerson] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [establishmentDate, setEstablishmentDate] = useState(null);
  const [officeAddress, setOfficeAddress] = useState("");
  const [cityOfOfficeLocation, setCityOfOfficeLocation] = useState("");
  const [postalCodeCompany, setPostalCodeCompany] = useState("");
  const [countryCompany, setCountryCompany] = useState("");
  const [email, setEmail] = useState("");
  const [pec, setPec] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [fax, setFax] = useState("");
  const formWizardRef = React.createRef();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  useEffect(() => {
    if (!show) {
      resetWizard();
    }
  });

  const getLocale = () => {
    switch (i18n.language) {
      case "it":
        return it;
      default:
        return enUS;
    }
  };

  const nextTab = () => {
    if (isLegalEntity && currentTabIndex === 0) {
      formWizardRef.current?.goToTab(2);
      setCurrentTabIndex(2);
    } else if (!isLegalEntity && currentTabIndex === 1) {
      formWizardRef.current?.goToTab(3);
      setCurrentTabIndex(3);
    } else {
      formWizardRef.current?.nextTab();
      setCurrentTabIndex((prevIndex) => prevIndex + 1);
    }
  };

  const backTab = () => {
    if (isLegalEntity && currentTabIndex === 2) {
      formWizardRef.current?.goToTab(0);
      setCurrentTabIndex(0);
    } else if (!isLegalEntity && currentTabIndex === 3) {
      formWizardRef.current?.goToTab(1);
      setCurrentTabIndex(1);
    } else {
      formWizardRef.current?.prevTab();
      setCurrentTabIndex((prevIndex) => Math.max(0, prevIndex - 1));
    }
  };

  const resetWizard = () => {
    setFiscalCode("");
    setVatNumber("");
    setIsLegalEntity(false);
    setExternalCode("");
    setResourceActive(false);
    setName("");
    setSurname("");
    setSelectedGender(null);
    setTitle("");
    setBirthDate(null);
    setBirthProvince("");
    setBirthCity("");
    setHomeAddress("");
    setCityOfResidence("");
    setPostalCodePerson("");
    setCountryPerson("");
    setCompanyName("");
    setEstablishmentDate(null);
    setOfficeAddress("");
    setCityOfOfficeLocation("");
    setPostalCodeCompany("");
    setCountryCompany("");
    setEmail("");
    setPec("");
    setPhone("");
    setMobile("");
    setFax("");
    setCurrentTabIndex(0);
  };

  const handleCreateResource = async () => {
    await updateToken();
    const resource = {
      active: resourceActive,
      address: isLegalEntity ? officeAddress : homeAddress,
      bornPlace: !isLegalEntity ? birthCity : null,
      bornProvince: !isLegalEntity ? birthProvince : null,
      companyName: isLegalEntity ? companyName : null,
      contactEmail: email,
      country: isLegalEntity ? countryCompany : countryPerson,
      externalKey: externalCode,
      fax: fax,
      fiscalCode: fiscalCode,
      company: isLegalEntity,
      legalEmail: pec,
      mobile: mobile,
      name: !isLegalEntity ? name : null,
      surname: !isLegalEntity ? surname : null,
      gender: !isLegalEntity ? selectedGender.value : "N",
      title: !isLegalEntity ? title : null,
      phone: phone,
      place: isLegalEntity ? cityOfOfficeLocation : cityOfResidence,
      vatNumber: vatNumber,
      zipCode: isLegalEntity ? postalCodeCompany : postalCodePerson,
      bornDate: isLegalEntity
        ? establishmentDate
            .toLocaleDateString("it-IT", dateOptions)
            .split("/")
            .reverse()
            .join("-")
        : birthDate
            .toLocaleDateString("it-IT", dateOptions)
            .split("/")
            .reverse()
            .join("-"),
    };
    try {
      await timeslotsApi.createResource(resource, token);
    } catch (error) {
      console.error("Error creating resource:", error);
      toast.error(t("createResourceError"));
    }
  };

  const handleComplete = async () => {
    await handleCreateResource();
    resetWizard();
    toast.success(t("resourceSaved"));
    handleClose();
    console.log("Form completed!");
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="large-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="green-color">
          {t("resourceCreation")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormWizard
          ref={formWizardRef}
          color={mainColor}
          stepSize="xs"
          onComplete={handleComplete}
          nextButtonTemplate={() => (
            <Button
              className="green-text-button fw-semibold border-none"
              onClick={nextTab}
            >
              {t("next")}
              <img
                src={`${process.env.PUBLIC_URL}/next.svg`}
                alt="Next"
                style={{
                  width: "1rem",
                }}
                className="ml-2 mb-1"
              />
            </Button>
          )}
          backButtonTemplate={() => (
            <Button
              className="green-text-button fw-semibold mr-2 border-none"
              onClick={backTab}
            >
              <img
                src={`${process.env.PUBLIC_URL}/back.svg`}
                alt="Back"
                style={{
                  width: "1rem",
                }}
                className="mr-2 mb-1"
              />
              {t("back")}
            </Button>
          )}
          finishButtonTemplate={(handleComplete) => (
            <Button
              className="white-text-button fw-semibold mr-2"
              onClick={handleComplete}
            >
              <img
                src={`${process.env.PUBLIC_URL}/save.svg`}
                alt="Back"
                style={{
                  width: "1rem",
                }}
                className="mr-2 mb-1"
              />
              {t("saveResource")}
            </Button>
          )}
        >
          <FormWizard.TabContent title={t("resourceData")} icon=" ">
            <div className="row text-start">
              <div className="col-3">
                <Form.Group controlId="fiscalCodeForm">
                  <Form.Label className="green-color">
                    {t("fiscalCode")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("fiscalCode")}
                    className="w-100 custom-form-control"
                    value={fiscalCode}
                    onChange={(e) => setFiscalCode(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Group controlId="VATNumberForm">
                  <Form.Label className="green-color">
                    {t("VATNumber")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("VATNumber")}
                    className="w-100 custom-form-control"
                    value={vatNumber}
                    onChange={(e) => setVatNumber(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Label className="green-color">
                  {t("legalEntity")}
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  id="legalEntityCheckbox"
                  className="custom-switch"
                  label={t("legalEntity")}
                  checked={isLegalEntity}
                  onChange={(e) => setIsLegalEntity(e.target.checked)}
                />
              </div>
              <div className="col-3">
                <Form.Group controlId="externalCodeForm">
                  <Form.Label className="green-color">
                    {t("externalCode")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("externalCode")}
                    className="w-100 custom-form-control"
                    value={externalCode}
                    onChange={(e) => setExternalCode(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div
                  className="light-green-background d-flex align-items-center justify-content-end"
                  style={{ height: "2.5rem" }}
                >
                  <Form.Group
                    controlId="resourceActiveSwitch"
                    className="d-flex align-items-center mb-0"
                  >
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ lineHeight: "2.5rem" }}
                    >
                      {t("resourceActive")}
                    </Form.Label>
                    <Form.Check
                      type="switch"
                      id="setResourceActiveSwitch"
                      className="custom-switch"
                      checked={resourceActive}
                      onChange={(e) => setResourceActive(e.target.checked)}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("individualPerson")} icon=" ">
            <div className="row text-start">
              <div className="col-3">
                <Form.Group controlId="nameForm">
                  <Form.Label className="green-color">{t("name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("name")}
                    className="w-100 custom-form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Group controlId="surnameForm">
                  <Form.Label className="green-color">
                    {t("surname")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("surname")}
                    className="w-100 custom-form-control"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-1">
                <Form.Group controlId="genderForm">
                  <Form.Label className="green-color">{t("gender")}</Form.Label>
                  <Select
                    classNamePrefix="custom-select"
                    placeholder={""}
                    value={selectedGender}
                    onChange={setSelectedGender}
                    options={[
                      { value: "M", label: "M" },
                      { value: "F", label: "F" },
                    ]}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: mainColor,
                        primary: mainColor,
                        neutral20: "#dee2e6",
                      },
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-2">
                <Form.Group controlId="titleForm">
                  <Form.Label className="green-color">{t("title")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("title")}
                    className="w-100 custom-form-control"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Group controlId="birthDateForm">
                  <Form.Label className="green-color d-flex align-items-center">
                    {t("birthDate")}
                    <img
                      src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                      alt="Calendar"
                      style={{
                        width: "1rem",
                      }}
                      className="ml-2"
                    />
                  </Form.Label>
                  <div className="date-picker-wrapper w-100">
                    <DatePicker
                      placeholderText={t("birthDate")}
                      locale={getLocale()}
                      selected={birthDate}
                      onChange={(date) => setBirthDate(date)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      maxDate={new Date()}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-4">
                <Form.Group controlId="birthProvinceForm">
                  <Form.Label className="green-color">
                    {t("birthProvince")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("birthProvince")}
                    className="w-100 custom-form-control"
                    value={birthProvince}
                    onChange={(e) => setBirthProvince(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-4">
                <Form.Group controlId="birthCityForm">
                  <Form.Label className="green-color">
                    {t("birthCity")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("birthCity")}
                    className="w-100 custom-form-control"
                    value={birthCity}
                    onChange={(e) => setBirthCity(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-4">
                <Form.Group controlId="homeAddressForm">
                  <Form.Label className="green-color">
                    {t("homeAddress")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("homeAddress")}
                    className="w-100 custom-form-control"
                    value={homeAddress}
                    onChange={(e) => setHomeAddress(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-4">
                <Form.Group controlId="cityOfResidenceForm">
                  <Form.Label className="green-color">
                    {t("cityOfResidence")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("cityOfResidence")}
                    className="w-100 custom-form-control"
                    value={cityOfResidence}
                    onChange={(e) => setCityOfResidence(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-4">
                <Form.Group controlId="postalCodeForm">
                  <Form.Label className="green-color">
                    {t("postalCode")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("postalCode")}
                    className="w-100 custom-form-control"
                    value={postalCodePerson}
                    onChange={(e) => setPostalCodePerson(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-4">
                <Form.Group controlId="countryForm">
                  <Form.Label className="green-color">
                    {t("country")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("country")}
                    className="w-100 custom-form-control"
                    value={countryPerson}
                    onChange={(e) => setCountryPerson(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("legalEntity")} icon=" ">
            <div className="row text-start">
              <div className="col-6">
                <Form.Group controlId="companyNameForm">
                  <Form.Label className="green-color">
                    {t("companyName")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("companyName")}
                    className="w-100 custom-form-control"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group controlId="establishmentDateForm">
                  <Form.Label className="green-color d-flex align-items-center">
                    {t("establishmentDate")}
                    <img
                      src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                      alt="Calendar"
                      style={{
                        width: "1rem",
                      }}
                      className="ml-2"
                    />
                  </Form.Label>
                  <div className="date-picker-wrapper w-100">
                    <DatePicker
                      placeholderText={t("establishmentDate")}
                      locale={getLocale()}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      selected={establishmentDate}
                      onChange={(date) => setEstablishmentDate(date)}
                      maxDate={new Date()}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-3">
                <Form.Group controlId="officeAddressForm">
                  <Form.Label className="green-color">
                    {t("officeAddress")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("officeAddress")}
                    className="w-100 custom-form-control"
                    value={officeAddress}
                    onChange={(e) => setOfficeAddress(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Group controlId="cityOfOfficeLocationForm">
                  <Form.Label className="green-color">
                    {t("cityOfOfficeLocation")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("cityOfOfficeLocation")}
                    className="w-100 custom-form-control"
                    value={cityOfOfficeLocation}
                    onChange={(e) => setCityOfOfficeLocation(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Group controlId="postalCodeForm">
                  <Form.Label className="green-color">
                    {t("postalCode")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("postalCode")}
                    className="w-100 custom-form-control"
                    value={postalCodeCompany}
                    onChange={(e) => setPostalCodeCompany(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Group controlId="countryForm">
                  <Form.Label className="green-color">
                    {t("country")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("country")}
                    className="w-100 custom-form-control"
                    value={countryCompany}
                    onChange={(e) => setCountryCompany(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("contacts")} icon=" ">
            <div className="row text-start">
              <div className="col-6">
                <Form.Group controlId="emailForm">
                  <Form.Label className="green-color">{t("email")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("email")}
                    className="w-100 custom-form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group controlId="pecForm">
                  <Form.Label className="green-color">{t("pec")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("pec")}
                    className="w-100 custom-form-control"
                    value={pec}
                    onChange={(e) => setPec(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-4">
                <Form.Group controlId="phoneForm">
                  <Form.Label className="green-color">{t("phone")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("phone")}
                    className="w-100 custom-form-control"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-4">
                <Form.Group controlId="mobileForm">
                  <Form.Label className="green-color">{t("mobile")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("mobile")}
                    className="w-100 custom-form-control"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-4">
                <Form.Group controlId="faxForm">
                  <Form.Label className="green-color">{t("fax")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("fax")}
                    className="w-100 custom-form-control"
                    value={fax}
                    onChange={(e) => setFax(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("dataConfirmation")} icon=" ">
            <div className="row text-start">
              <div className="col-3">
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="fiscalCodeForm">
                      <Form.Label className="green-color">
                        {t("fiscalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("fiscalCode")}
                        className="w-100 custom-form-control"
                        value={fiscalCode}
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="VATNumberForm">
                      <Form.Label className="green-color">
                        {t("VATNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("VATNumber")}
                        className="w-100 custom-form-control"
                        value={vatNumber}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Label className="green-color">
                      {t("legalEntity")}
                    </Form.Label>
                    <Form.Check
                      type="checkbox"
                      id="legalEntityCheckbox"
                      className="custom-switch"
                      label={t("legalEntity")}
                      checked={isLegalEntity}
                      disabled
                    />
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="externalCodeForm">
                      <Form.Label className="green-color">
                        {t("externalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("externalCode")}
                        className="w-100 custom-form-control"
                        value={externalCode}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Form.Group
                      controlId="resourceActiveSwitch"
                      className="d-flex align-items-center mb-0"
                    >
                      <Form.Label
                        className="mb-0 mr-1"
                        style={{ lineHeight: "2.5rem" }}
                      >
                        {t("resourceActive")}
                      </Form.Label>
                      <Form.Check
                        type="switch"
                        id="setResourceActiveSwitch"
                        className="custom-switch"
                        checked={resourceActive}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Form.Check
                      type="checkbox"
                      id="saveDataCheckbox"
                      className="custom-switch light-green-background"
                      label={t("saveData")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-9">
                {!isLegalEntity ? (
                  <div className="row">
                    <div className="col-3">
                      <Form.Group controlId="nameForm">
                        <Form.Label className="green-color">
                          {t("name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("name")}
                          className="w-100 custom-form-control"
                          value={name}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-3">
                      <Form.Group controlId="surnameForm">
                        <Form.Label className="green-color">
                          {t("surname")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("surname")}
                          className="w-100 custom-form-control"
                          value={surname}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-1">
                      <Form.Group controlId="genderForm">
                        <Form.Label className="green-color">
                          {t("gender")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="w-100 custom-form-control"
                          value={selectedGender ? selectedGender.value : ""}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-2">
                      <Form.Group controlId="titleForm">
                        <Form.Label className="green-color">
                          {t("title")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("title")}
                          className="w-100 custom-form-control"
                          value={title}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-3">
                      <Form.Group controlId="birthDateForm">
                        <Form.Label className="green-color d-flex align-items-center">
                          {t("birthDate")}
                          <img
                            src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                            alt="Calendar"
                            style={{
                              width: "1rem",
                            }}
                            className="ml-2"
                          />
                        </Form.Label>
                        <div className="date-picker-wrapper w-100">
                          <DatePicker
                            placeholderText={t("birthDate")}
                            locale={getLocale()}
                            selected={birthDate}
                            disabled
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="birthProvinceForm">
                        <Form.Label className="green-color">
                          {t("birthProvince")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("birthProvince")}
                          className="w-100 custom-form-control"
                          value={birthProvince}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="birthCityForm">
                        <Form.Label className="green-color">
                          {t("birthCity")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("birthCity")}
                          className="w-100 custom-form-control"
                          value={birthCity}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="homeAddressForm">
                        <Form.Label className="green-color">
                          {t("homeAddress")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("homeAddress")}
                          className="w-100 custom-form-control"
                          value={homeAddress}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="cityOfResidenceForm">
                        <Form.Label className="green-color">
                          {t("cityOfResidence")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("cityOfResidence")}
                          className="w-100 custom-form-control"
                          value={cityOfResidence}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="postalCodeForm">
                        <Form.Label className="green-color">
                          {t("postalCode")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("postalCode")}
                          className="w-100 custom-form-control"
                          value={postalCodePerson}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="countryForm">
                        <Form.Label className="green-color">
                          {t("country")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("country")}
                          className="w-100 custom-form-control"
                          value={countryPerson}
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-4">
                      <Form.Group controlId="companyNameForm">
                        <Form.Label className="green-color">
                          {t("companyName")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("companyName")}
                          className="w-100 custom-form-control"
                          value={companyName}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="establishmentDateForm">
                        <Form.Label className="green-color d-flex align-items-center">
                          {t("establishmentDate")}
                          <img
                            src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                            alt="Calendar"
                            style={{
                              width: "1rem",
                            }}
                            className="ml-2"
                          />
                        </Form.Label>
                        <div className="date-picker-wrapper w-100">
                          <DatePicker
                            placeholderText={t("establishmentDate")}
                            locale={getLocale()}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            selected={establishmentDate}
                            disabled
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="officeAddressForm">
                        <Form.Label className="green-color">
                          {t("officeAddress")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("officeAddress")}
                          className="w-100 custom-form-control"
                          value={officeAddress}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="cityOfOfficeLocationForm">
                        <Form.Label className="green-color">
                          {t("cityOfOfficeLocation")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("cityOfOfficeLocation")}
                          className="w-100 custom-form-control"
                          value={cityOfOfficeLocation}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="postalCodeForm">
                        <Form.Label className="green-color">
                          {t("postalCode")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("postalCode")}
                          className="w-100 custom-form-control"
                          value={postalCodeCompany}
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="countryForm">
                        <Form.Label className="green-color">
                          {t("country")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("country")}
                          className="w-100 custom-form-control"
                          value={countryCompany}
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                <div className="row text-start">
                  <div className="col-6">
                    <Form.Group controlId="emailForm">
                      <Form.Label className="green-color">
                        {t("email")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("email")}
                        className="w-100 custom-form-control"
                        value={email}
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="pecForm">
                      <Form.Label className="green-color">
                        {t("pec")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("pec")}
                        className="w-100 custom-form-control"
                        value={pec}
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group controlId="phoneForm">
                      <Form.Label className="green-color">
                        {t("phone")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("phone")}
                        className="w-100 custom-form-control"
                        value={phone}
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group controlId="mobileForm">
                      <Form.Label className="green-color">
                        {t("mobile")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("mobile")}
                        className="w-100 custom-form-control"
                        value={mobile}
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group controlId="faxForm">
                      <Form.Label className="green-color">
                        {t("fax")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("fax")}
                        className="w-100 custom-form-control"
                        value={fax}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
        </FormWizard>
      </Modal.Body>
    </Modal>
  );
}

export default ModalCreateResource;
