import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../AuthContext";

function ModalFatt({ show, handleClose, timeslot, onUpdate }) {
  const [billable, setBillable] = useState(timeslot.billable || timeslot.time); 
  const [isBillableEnabled, setIsBillableEnabled] = useState(false);
  const { t } = useTranslation();
  const { token, updateToken } = useAuth();

  useEffect(() => {
    if (timeslot) {
      setBillable(timeslot.billable || timeslot.time); 
      setIsBillableEnabled(false); 
    }
  }, [timeslot]);

  const handleSaveBillable = async () => {
    const updatedTimeslot = {
      ...timeslot,
      billable, 
    };

    try {
      await updateToken();
      if (token) {
        await timeslotsApi.updateTimeslot(timeslot.id, updatedTimeslot, token);
        onUpdate(updatedTimeslot);
        toast.success(t('billableHoursSaved'));
        handleClose();
      }     
    } catch (error) {
      toast.error(t('billableHoursError'));
      console.error("Error while saving billable hours:", error);
    }
  };

  const handleResetBillable = async () => {
    setBillable(timeslot.time); 
    setIsBillableEnabled(false); 
    const updatedTimeslot = {
      ...timeslot,
      billable: timeslot.time, 
    };
    try {
      await updateToken(); 
      if (token) {
        await timeslotsApi.updateTimeslot(timeslot.id, updatedTimeslot, token);
        onUpdate(updatedTimeslot);
        toast.success(t('billableHoursSaved'));
      }
    } catch (error) {
      toast.error(t('billableHoursError'));
      console.error("Error while saving billable hours:", error);
    }
  };

  const handleCheckboxChange = () => {
    setIsBillableEnabled(!isBillableEnabled);
    if (!isBillableEnabled) {
      setBillable(""); 
    } else {
      setBillable(timeslot.billable || timeslot.time); 
    }
  };

  const formatTime = (time) => {
    let [hours, minutes] = time.split(':');
    if (!minutes) {
      minutes = '00'; 
    }
    hours = hours.padStart(2, '0'); 
    return `${hours}:${minutes}`;
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="green-color">{t('billability')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTime">
            <Form.Label className="green-color">{t('hours')}</Form.Label>
            <Form.Control
              type="text"
              className="custom-form-control"
              value={timeslot.time.split(":").slice(0, 2).join(":")}
              readOnly
              style={{ fontSize: "1.1rem" }}
            />
          </Form.Group>

          <Form.Group controlId="formBillable" className="mt-4">
            <Form.Check
              type="checkbox"
              className="custom-switch"
              label={t('changeBillableHours')}
              checked={isBillableEnabled}
              onChange={handleCheckboxChange}
            />
            <Form.Control
              type="text"
              value={billable.split(":").slice(0, 2).join(":")}
              onChange={(e) => setBillable(formatTime(e.target.value))}
              disabled={!isBillableEnabled}
              style={{ fontSize: "1.1rem" }}
              className="mt-2 custom-form-control"
              placeholder={t('billableHoursPlaceholder')}
            />
          </Form.Group>

          <div className="d-flex justify-content-between mt-2">
            <Button
              className="green-background text-white me-2 fw-semibold"
              onClick={handleSaveBillable}
            >
              {t('save')}
            </Button>
            <Button className="green-text-button fw-semibold" onClick={handleResetBillable}>
              {t('reset')}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalFatt;
