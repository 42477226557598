import React from 'react';
import { Navbar } from 'react-bootstrap';

const MainHeader = () => {
  return (
    <Navbar id="navbar" fixed="top" className="main-header px-1 py-2">
        <Navbar.Brand href="/" className="main-header-brand">
          <img
            src={`${process.env.PUBLIC_URL}/img_header.svg`}
            alt="Logo"
            className="main-header-logo"
          />
        </Navbar.Brand>
    </Navbar>
  );
};

export default MainHeader;
