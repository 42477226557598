import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../AuthContext";
import {
  Table,
  Tabs,
  Tab,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import ModalDescNote from "./components/ModalDescNote";
import ModalFatt from "./components/ModalFatt";
import ModalPlaceTimeslot from "./components/ModalPlaceTimeslot";
import ModalLeavesType from "./components/ModalLeavesType";
import ModalInsertTimeslot from "./components/ModalInsertTimeslot";
import ModalConfirmation from "./components/ModalConfirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { timeslotsApi } from "../misc/TimeslotsApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import WeekSelector from "./components/WeekSelector";
import Loading from "./components/Loading";

function Timesheet() {

  const { token, updateToken } = useAuth();
  const [timeslots, setTimeslots] = useState([]);
  const [leaves, setLeaves] = useState({});
  const [currentTimeslot, setCurrentTimeslot] = useState(null);
  const [timeslotOrder, setTimeslotOrder] = useState([]);
  const [editedTime, setEditedTime] = useState(null);
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [currentLeave, setCurrentLeave] = useState(null);
  const [editedLeave, setEditedLeave] = useState(null);
  const [isEditingLeave, setIsEditingLeave] = useState(false);
  const [showModalDescNote, setShowModalDescNote] = useState(false);
  const [showModalFatt, setShowModalFatt] = useState(false);
  const [showModalPlace, setShowModalPlace] = useState(false);
  const [showModalLeaveType, setShowModalLeaveType] = useState(false);
  const [showModalInsertTimeslot, setShowModalInsertTimeslot] = useState(false);
  const [referenceDate, setReferenceDate] = useState(new Date());
  const [viewMode, setViewMode] = useState("task");
  const [sortAscending, setSortAscending] = useState(true);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const daysOfWeek = [
    t("daysOfWeek.Mon"),
    t("daysOfWeek.Tue"),
    t("daysOfWeek.Wed"),
    t("daysOfWeek.Thu"),
    t("daysOfWeek.Fri"),
    t("daysOfWeek.Sat"),
    t("daysOfWeek.Sun"),
  ];
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showModalConfirmationApproval, setShowModalConfirmationApproval] = useState(false);
  
  //Fetching
  const fetchTimeslots = useCallback(
    async (date) => {
      const currentMonday = new Date(date);
      currentMonday.setDate(date.getDate() - date.getDay() + 1);
      setLoading(true);
      try {
        await updateToken();
        const dateOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        };
        const timeslotsData = await timeslotsApi.getWeekTimeslots(
          currentMonday.toLocaleDateString('it-IT', dateOptions)
          .split('/').reverse().join('-'),
          token
        );
        setTimeslots(timeslotsData ? timeslotsData.data : []);
      } catch (error) {
        console.error("Error while loading timesheet:", error);
        setTimeslots([]);
        if (error.response?.status === 401) {
          console.log("Token might be expired. Attempting to update token...");
          await updateToken();
          fetchTimeslots(date);
        } else {
          console.error("Error while loading timesheet:", error);
          setTimeslots([]);
        }
      } finally {
        setLoading(false);
      }
    },
    [token, updateToken]
  );

  const fetchLeaves = useCallback(
    async (date) => {
      const currentMonday = new Date(date);
      currentMonday.setDate(date.getDate() - date.getDay() + 1);
      setLoading(true);
      try {
        await updateToken();
        const dateOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        };
        const leavesData = await timeslotsApi.getWeekLeaves(
          currentMonday.toLocaleDateString('it-IT', dateOptions)
          .split('/').reverse().join('-'),
          token
        );
        if (leavesData.status === 404) {
          setLeaves([]);
        } else {
          const leavesByDate = {};
          leavesData.data.forEach((leave) => {
            if (!leavesByDate[leave.date]) {
              leavesByDate[leave.date] = leave;
            }
          });
          setLeaves(leavesByDate);
        }
      } catch (error) {
        console.error("Error while loading leaves:", error);
        if (error.response?.status === 401) {
          console.log("Token might be expired. Attempting to update token...");
          await updateToken();
          fetchLeaves(date);
        } else {
          console.error("Error while loading leaves:", error);
        }
      } finally {
        setLoading(false);
      }
    },
    [token, updateToken]
  );

  //Use effect
  useEffect(() => {
    fetchLeaves(referenceDate);
    fetchTimeslots(referenceDate);
  }, [referenceDate, fetchTimeslots, fetchLeaves]);

  useEffect(() => {
    if (timeslots.length > 0) {
      const initialOrder = timeslots.map((slot) => slot.id);
      setTimeslotOrder(initialOrder);
    }
  }, [timeslots]);

  useEffect(() => {
    const currentMonday = new Date(referenceDate);
    currentMonday.setDate(referenceDate.getDate() - referenceDate.getDay() + 1);
    const currentSunday = new Date(currentMonday);
    currentSunday.setDate(currentMonday.getDate() + 6);
    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedStartDate = currentMonday
      .toLocaleDateString('it-IT', dateOptions)
      .split('/')
      .reverse()
      .join('-');
    const formattedEndDate = currentSunday
      .toLocaleDateString('it-IT', dateOptions)
      .split('/')
      .reverse()
      .join('-');
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, [referenceDate]);

  //Service functions
  const generateDays = () => {
    const startOfWeek = new Date(referenceDate);
    startOfWeek.setDate(referenceDate.getDate() - referenceDate.getDay() + 1);

    return daysOfWeek.map((day, index) => {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + index);
      return {
        day: `${day} ${date.getDate()}`,
        date: date.toLocaleDateString('it-IT', dateOptions)
        .split('/').reverse().join('-'),
      };
    });
  };

  const days = generateDays();

  const sumTimes = (times) => {
    let totalSeconds = 0;
    times.forEach((time) => {
      if (!time) return; 
      const parts = time.split(":").map(Number);
      const [hours = 0, minutes = 0, seconds = 0] = parts; 
      totalSeconds += hours * 3600 + minutes * 60 + seconds;
    });
    const totalHours = Math.floor(totalSeconds / 3600);
    const totalMinutes = Math.floor((totalSeconds % 3600) / 60);
    return `${String(totalHours).padStart(2, "0")}:${String(totalMinutes).padStart(2, "0")}`;
  };

  const getPreviousMonday = (date) => {
    const currentMonday = new Date(date);
    currentMonday.setDate(date.getDate() - date.getDay() + 1);
    const previousMonday = new Date(currentMonday);
    previousMonday.setDate(currentMonday.getDate() - 7);
    return previousMonday.toLocaleDateString('it-IT', dateOptions).split('/').reverse().join('-');
  };

  const areAllTimeslotsClosed = () => {
    return timeslots.every((timeslot) => timeslot.closed);
  };

  const calculateTotalTime = (type) => {
    let total;
    if (type === "work") {
      total = timeslots
      .filter(timeslot => days.some(day => day.date === timeslot.date))
      .map(timeslot => timeslot.time || "00:00");
    } else {
      total = Object.values(leaves)
        .filter(leave => days.some(day => day.date === leave.date))
        .map((leave) => leave.time || "00:00");
    }
    return sumTimes(total);
  };

  const handleOpenModalConfirmationApproval = () => setShowModalConfirmationApproval(true);
  const handleCloseModalConfirmationApproval = () => setShowModalConfirmationApproval(false);

  const formatTime = (time) => {
    let [hours, minutes] = time.split(':');
    if (!minutes) {
      minutes = '00'; 
    }
    hours = hours.padStart(2, '0'); 
    return `${hours}:${minutes}`;
  };

  //Grouping functions
  const groupByTask = (timeslots) => {
    const grouped = {};
    timeslots.forEach((slot) => {
      const uniqueKey = slot.subtask ? `${slot.task?.id}-${slot.subtask.id}` : slot.task?.id;
      const taskName = slot.subtask
        ? `${slot.subtask.internalCode} - ${slot.subtask.name}`
        : `${slot.task.internalCode} - ${slot.task.name}`;
        
      if (!grouped[uniqueKey]) {
        grouped[uniqueKey] = {
          name: taskName,
          slots: [],
        };
      }
      grouped[uniqueKey].slots.push(slot);
    });
    return grouped;
  };

  const groupByProject = (timeslots) => {
    const grouped = {};
    timeslots.forEach((slot) => {
      const projectId = `${slot.project?.id}`;
      const projectCode = slot.project?.internalCode;
      const projectName = slot.project?.name;
      if (!grouped[projectId]) {
        grouped[projectId] = {
          internalCode: projectCode,
          name: projectName,
          slots: [],
        };
      }
      grouped[projectId].slots.push(slot);
    });
    return grouped;
  };

  const groupByField = (timeslots) => {
    const grouped = {};
    timeslots.forEach((slot) => {
      const fieldId = `${slot.field?.id}`;
      const fieldCode = slot.field?.internalCode;
      const fieldName = slot.field?.name;
      if (!grouped[fieldId]) {
        grouped[fieldId] = {
          internalCode: fieldCode,
          name: fieldName,
          slots: [],
        };
      }
      grouped[fieldId].slots.push(slot);
    });
    return grouped;
  };

  const groupByCustomer = (timeslots) => {
    const grouped = {};
    timeslots.forEach((slot) => {
      const customerId = `${slot.customer?.id}`;
      const resource = slot.customer?.resource;
      const displayName = resource?.company
        ? resource.companyName
        : `${resource?.name} ${resource?.surname}`;
      if (!grouped[customerId]) {
        grouped[customerId] = {
          name: displayName,
          slots: [],
        };
      }
      grouped[customerId].slots.push(slot);
    });
    return grouped;
  };

  const groupedTasks = groupByTask(timeslots);
  const groupedProjects = groupByProject(timeslots);
  const groupedFields = groupByField(timeslots);
  const groupedCustomers = groupByCustomer(timeslots);

  //Handle functions
  const handleShowModalDescNoteTimeslot = (timeslot) => {
    setCurrentTimeslot(timeslot);
    setCurrentLeave(null);
    setShowModalDescNote(true);
  };

  const handleShowModalDescNoteLeave = (leave) => {
    setCurrentLeave(leave);
    setCurrentTimeslot(null);
    setShowModalDescNote(true);
  };

  const handleCloseModalDescNoteTimeslot = () => {
    setShowModalDescNote(false);
    setCurrentTimeslot(null);
  };

  const handleCloseModalDescNoteLeave = () => {
    setShowModalDescNote(false);
    setCurrentLeave(null);
  };

  const handleUpdateTimeslot = (updatedTimeslot) => {
    const newTimeslots = timeslots.map((slot) =>
      slot.id === updatedTimeslot.id ? updatedTimeslot : slot
    );
    setTimeslots(newTimeslots);
  };

  const handleUpdateLeave = (updatedLeave) => {
    const updatedLeaves = {
      ...leaves,
      [currentLeave.date]: updatedLeave,
    };
    setLeaves(updatedLeaves);
  };

  const handleShowModalFatt = (timeslot) => {
    setCurrentTimeslot(timeslot);
    setShowModalFatt(true);
  };

  const handleCloseModalFatt = () => {
    setShowModalFatt(false);
    setCurrentTimeslot(null);
  };

  const handleTimeChange = (timeslot, newTime) => {
    setEditedTime({ ...timeslot, time: newTime });
  };

  const handleShowModalPlace = (timeslot) => {
    setCurrentTimeslot(timeslot);
    setShowModalPlace(true);
  };

  const handleCloseModalPlace = () => {
    setShowModalPlace(false);
    setCurrentTimeslot(null);
  };

  const handleShowModalLeaveType = (leave) => {
    setCurrentLeave(leave);
    setShowModalLeaveType(true);
  };

  const handleCloseModalLeaveType = () => {
    setShowModalLeaveType(false);
    setCurrentLeave(null);
  };

  const handleSaveTime = async (timeslot) => {
    const updatedTimeslot = {
      ...timeslot,
      time: editedTime.time,
      billable: editedTime.time,
    };
    try {
      await updateToken();
      await timeslotsApi.updateTimeslot(timeslot.id, updatedTimeslot, token);
      setIsEditingTime(false);
      setEditedTime(null);
      toast.success(t("hoursSaved"));
      handleUpdateTimeslot(updatedTimeslot);
    } catch (error) {
      console.error("Error while saving timesheet hours:", error);
      if (error.response?.status === 401) {
        console.log("Token might be expired. Attempting to update token...");
        await updateToken();
        handleSaveTime(timeslot);
      } else {
        toast.error(t("hoursError"));
      }
    }
  };

  const handleCopyAllActivities = async () => {
    const previousMonday = getPreviousMonday(referenceDate);
    try {
      await updateToken();
      await timeslotsApi.copyAllActivities(previousMonday, token);
      toast.success(t("copyTimesheetSuccess"));
      const workflow = {
        startDate, 
        endDate,
      };
      await timeslotsApi.createWorkflow(workflow, token);
      await fetchTimeslots(referenceDate);
    } catch (error) {
      console.error("Error while copying activities:", error);
      if (error.response?.status === 401) {
        console.log("Token might be expired. Attempting to update token...");
        await updateToken();
        handleCopyAllActivities();
      } else {
        toast.error(t("copyTimesheetError"));
      }
    }
  };

  const handleLeaveChange = (leave, newTime) => {
    setEditedLeave({ ...leave, time: newTime });
  };

  const handleSaveLeave = async (leave) => {
    try {
      await updateToken();
      await timeslotsApi.updateLeave(leave.id, editedLeave, token);
      setIsEditingLeave(false);
      setEditedLeave(null);
      toast.success(t("leaveSaved"));
      fetchLeaves(referenceDate);
    } catch (error) {
      console.error("Error while saving leaves:", error);
      if (error.response?.status === 401) {
        console.log("Token might be expired. Attempting to update token...");
        await updateToken();
        handleSaveLeave(leave);
      } else {
        toast.error(t("leaveError"));
      }
    }
  };

  const handleCreateLeave = async (leave) => {
    try {
      await updateToken();
      await timeslotsApi.createLeave(leave, token);
      setIsEditingLeave(false);
      setEditedLeave(null);
      toast.success(t("leaveSaved"));
      const workflow = {
        startDate, 
        endDate,
      };
      await timeslotsApi.createWorkflow(workflow, token);
      fetchLeaves(referenceDate);
    } catch (error) {
      console.error("Error while creating leaves:", error);
      if (error.response?.status === 401) {
        console.log("Token might be expired. Attempting to update token...");
        await updateToken();
        handleCreateLeave(leave);
      } else {
        toast.error(t("leaveError"));
      }
    }
  };

  const handleDeleteRow = async (groupKey, group) => {
    try {
      await updateToken();
      const timeslotIds = group.slots.map((slot) => slot.id);
      for (const id of timeslotIds) {
        await timeslotsApi.deleteTimeslot(id, token);
      }
      toast.success(t("rowDeletedSuccess"));
      fetchTimeslots(referenceDate);
    } catch (error) {
      console.error("Error while deleting timeslots:", error);
      if (error.response?.status === 401) {
        console.log("Token might be expired. Attempting to update token...");
        await updateToken();
        handleDeleteRow(groupKey, group);
      } else {
        toast.error(t("rowDeletedError"));
      }
    }
  };

  const handleSubmitForApproval = async () => {
    const currentMonday = new Date(referenceDate);
    currentMonday.setDate(referenceDate.getDate() - referenceDate.getDay() + 1);
    try {
      await updateToken();
      await timeslotsApi.approveTimesheet(
        currentMonday.toLocaleDateString('it-IT', dateOptions).split('/').reverse().join('-'),
        token
      );
      toast.success(t("submitForApprovalSuccess"));
      await fetchTimeslots(referenceDate);
    } catch (error) {
      console.error("Error while submitting timesheet:", error);
      if (error.response?.status === 401) {
        console.log("Token might be expired. Attempting to update token...");
        await updateToken();
        handleSubmitForApproval();
      } else {
        toast.error(t("submitForApprovalError"));
      }
    }
  };

  const handleShowModalInsertTimeslot = () => {
    setShowModalInsertTimeslot(true);
  };

  const handleCloseModalInsertTimeslot = () => {
    setShowModalInsertTimeslot(false);
    fetchTimeslots(referenceDate);
  };

  const handleConfirmSendApproval = () => {
    setShowModalConfirmationApproval(false);
    handleSubmitForApproval();
  };

  //Row sorting
  const toggleSort = () => {
    const currentGrouping =
      viewMode === "task"
        ? groupedTasks
        : viewMode === "project"
        ? groupedProjects
        : viewMode === "field"
        ? groupedFields
        : groupedCustomers;

    const sortedGroups = Object.entries(currentGrouping).sort(
      ([, a], [, b]) => {
        const aName = a.name || a.internalCode || "";
        const bName = b.name || b.internalCode || "";
        return sortAscending
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      }
    );

    const newOrder = sortedGroups.flatMap(([, group]) =>
      group.slots.map((slot) => slot.id)
    );

    setTimeslotOrder(newOrder);
    setSortAscending(!sortAscending);
  };

  const sortedRender = (groupedData) => {

    function createUniqueKey(slot) {
      const {
        taskPk,
        projectPk,
        fieldPk,
        customerPk,
        partnerPk,
        subtaskPk
      } = slot;
      return `${taskPk || -1}_${projectPk || -1}_${fieldPk || -1}_${customerPk || -1}_${partnerPk || -1}_${subtaskPk || -1}`;
    };

    const regroupedData = Object.values(groupedData).flatMap(group => 
      group.slots.map(slot => ({
        uniqueKey: createUniqueKey(slot),
        name: group.name,
        internalCode: group.internalCode,
        slot: slot
      }))
    ).reduce((acc, item) => {
      if (!acc[item.uniqueKey]) {
        acc[item.uniqueKey] = {
          name: item.name,
          internalCode: item.internalCode,
          slots: []
        };
      }
      acc[item.uniqueKey].slots.push(item.slot);
      return acc;
    }, {});

    const groupKeys = Object.keys(regroupedData);
    const sortedKeys = groupKeys.sort((a, b) => {
      const aFirstSlotId = regroupedData[a].slots[0]?.id;
      const bFirstSlotId = regroupedData[b].slots[0]?.id;
      return (
        timeslotOrder.indexOf(aFirstSlotId) -
        timeslotOrder.indexOf(bFirstSlotId)
      );
    });

    return sortedKeys
      .filter((groupKey) =>
        days.some((day) =>
          regroupedData[groupKey].slots.some((slot) => slot.date === day.date)
        )
      )
      .map((groupKey) => {
        const group = regroupedData[groupKey];
        return (
          <tr key={groupKey}>
            <td>
              {viewMode === "task" ? (
                <span>{group.name}</span>
              ) : viewMode === "project" || viewMode === "field" ? (
                <div className="d-flex flex-column">
                  <strong>{group.internalCode ? group.internalCode : t("notDefined")}</strong>
                  <span>{group.name ? group.name : t("notDefined")}</span>
                </div>
              ) : (
                <span>{group.name}</span>
              )}
            </td>
            {days.map((day) => {
              const slotsForDay = group.slots.filter(
                (slot) => slot.date === day.date
              );
              return (
                <td
                  key={day.date}
                  className="text-center justify-content-center px-0"
                >
                  {slotsForDay.length > 0 ? (
                    <div className="d-flex p-0 justify-content-center align-items-center">
                      {slotsForDay.map((slot) => (
                        <div
                          key={slot.id}
                          className="d-flex align-items-center"
                        >
                          {isEditingTime && editedTime?.id === slot.id ? (
                            <input
                              type="text"
                              value={
                                editedTime.time.split(":").slice(0, 2).join(":") === "00:00" 
                                  ? "" 
                                  : editedTime.time.split(":").slice(0, 2).join(":")
                              }
                              onChange={(e) =>
                                handleTimeChange(slot, formatTime(e.target.value))
                              }
                              onBlur={() => handleSaveTime(slot)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSaveTime(slot);
                                }
                              }}
                              className="form-control cell-form"
                              placeholder="HH:mm"
                              autoFocus
                            />
                          ) : (
                            <>
                              {slot.closed ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-${slot.id}`}>
                                      {t("timesheetClosed")}
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    style={{
                                      cursor: "not-allowed",
                                      marginRight: "8px"
                                    }}
                                    className="min-w-cell"
                                  >
                                    {slot.time
                                      .split(":")
                                      .slice(0, 2)
                                      .join(":") === "00:00"
                                      ? "-"
                                      : slot.time
                                          .split(":")
                                          .slice(0, 2)
                                          .join(":")}
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                <span
                                  onClick={() => {
                                    if (!slot.closed) {
                                      setEditedTime(slot);
                                      setIsEditingTime(true);
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "8px"
                                  }}
                                  className="min-w-cell"
                                >
                                  {slot.time
                                    .split(":")
                                    .slice(0, 2)
                                    .join(":") === "00:00"
                                    ? "-"
                                    : slot.time
                                        .split(":")
                                        .slice(0, 2)
                                        .join(":")}
                                </span>
                              )}
                              <div className="d-flex flex-column align-items-center">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-${slot.id}`}>
                                      {slot.closed
                                        ? t("timesheetClosed")
                                        : slot.place ? slot.place.name : t("placeNotDefined")}
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={
                                      slot.place
                                        ? `${process.env.PUBLIC_URL}/${slot.place?.icon}_g.svg`
                                        : `${process.env.PUBLIC_URL}/not_found_g.svg`
                                    }
                                    alt="Place"
                                    style={{
                                      width: slot.place ? "1rem" : "0.66rem",
                                      cursor: slot.closed
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    className="m-1"
                                    onClick={() => {
                                      if (!slot.closed) {
                                        handleShowModalPlace(slotsForDay[0]);
                                      }
                                    }}
                                  />
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-${slot.id}`}>
                                      {slot.closed
                                        ? t("timesheetClosed")
                                        : t("descriptionAndNotes")}
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/notes.svg`}
                                    alt="Notes"
                                    style={{
                                      width: "1rem",
                                      cursor: slot.closed
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    className="m-1"
                                    onClick={() => {
                                      if (!slot.closed) {
                                        handleShowModalDescNoteTimeslot(
                                          slotsForDay[0]
                                        );
                                      }
                                    }}
                                  />
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-${slot.id}`}>
                                      {t("billability")}
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/billability.svg`}
                                    alt="Billability"
                                    style={{ width: "1rem", cursor: "pointer" }}
                                    className="m-1"
                                    onClick={() => {
                                      handleShowModalFatt(slotsForDay[0]);
                                    }}
                                  />
                                </OverlayTrigger>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center min-w-cell">-</div>
                  )}
                </td>
              );
            })}
            <td className="text-center vertical-align-middle">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-x`}>
                    {group.slots.some(slot => slot.closed)
                      ? t("timesheetClosed")
                      : t("deleteRow")}
                  </Tooltip>
                }
              >
              <img
                src={`${process.env.PUBLIC_URL}/x_g.svg`}
                alt="Delete"
                style={{ width: "1rem", cursor: group.slots.some(slot => slot.closed) ? "not-allowed" : "pointer"}}
                className="m-1"
                onClick={() => {
                  if (!group.slots.some(slot => slot.closed)) {handleDeleteRow(groupKey, group);}
                }}
              />
              </OverlayTrigger>
            </td>
          </tr>
        );
      });
  };

  //Timesheet render
  return (
    <div className="row">
      <div className="col-6 text-start">
        <h4 className="fw-semibold">Timesheet</h4>
      </div>
      <div className="row">
        <div className="col-3 text-start">
          <Button
            className="green-text-button fw-semibold"
            onClick={handleShowModalInsertTimeslot}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="arrow-icon mr-1"
            />
            {t("insertTimeslot")}
          </Button>
        </div>
        <div className="col-6 text-center">
          <WeekSelector setReferenceDate={setReferenceDate} />
        </div>
        <div className="col-3 text-end">
          <Button
            className="green-text-button fw-semibold"
            onClick={handleOpenModalConfirmationApproval}
            disabled={areAllTimeslotsClosed()}
          >
            {t("submitForApproval")}{" "}
            <FontAwesomeIcon
              icon={faChevronRight}
              className="arrow-icon ml-1"
            />
          </Button>
        </div>
      </div>
      <div className="col-12">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Tabs
              id="controlled-tab"
              activeKey={viewMode}
              onSelect={(k) => setViewMode(k)}
              className="mb-1"
            >
              <Tab eventKey="task" title={t("task")}></Tab>
              <Tab eventKey="project" title={t("project")}></Tab>
              <Tab eventKey="field" title={t("field")}></Tab>
              <Tab eventKey="customer" title={t("customer")}></Tab>
            </Tabs>
            <Table hover responsive>
              <thead>
                <tr>
                  <th className="fw-semibold">
                    {viewMode === "task"
                      ? t("orderByName")
                      : viewMode === "project"
                      ? t("orderByProjectCodeAndName")
                      : viewMode === "field"
                      ? t("orderByFieldCodeAndName")
                      : viewMode === "customer"
                      ? t("orderByNameAndSurname")
                      : null}
                    <FontAwesomeIcon
                      icon={faSort}
                      className="ms-2 green-color"
                      onClick={toggleSort}
                      style={{
                        cursor: "pointer",
                        marginRight: "8px",
                      }}
                    />
                  </th>
                  {days.map((day, index) => (
                    <th
                      key={day.date}
                      className={
                        index === 5 || index === 6
                          ? "text-center fw-semibold green-color"
                          : "text-center fw-semibold"
                      }
                    >
                      {day.day}
                    </th>
                  ))}
                  <th className="text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/x_g.svg`}
                      alt="Delete"
                      style={{ width: "1.5rem" }}
                      className="m-1"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="fw-semibold"> 
                    {t("leaves")} {calculateTotalTime("leaves")})
                  </td>
                  {days.map((day) => (
                    <td
                      key={day.date}
                      className="text-center justify-content-center px-0 min-w-cell"
                    >
                      {leaves[day.date] ? (
                        isEditingLeave &&
                        editedLeave?.id === leaves[day.date].id ? (
                          <input
                            type="text"
                            value={editedLeave.time.split(":").slice(0, 2).join(":")}
                            onChange={(e) =>
                              handleLeaveChange(
                                leaves[day.date],
                                e.target.value
                              )
                            }
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSaveLeave(leaves[day.date])
                              }
                            }}
                            onBlur={() => handleSaveLeave(leaves[day.date])}
                            className="form-control cell-form mx-auto"
                            autoFocus
                          />
                        ) : (
                          <div className="d-flex p-0 justify-content-center align-items-center">
                            <>
                              {leaves[day.date].closed ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-${leaves[day.date].id}`}
                                    >
                                      {t("leaveClosed")}
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    style={{
                                      cursor: "not-allowed",
                                      marginRight: "8px"
                                    }}
                                    className="fw-semibold min-w-cell"
                                  >
                                      {leaves[day.date].time
                                        ? leaves[day.date].time
                                            .split(":")
                                            .slice(0, 2)
                                            .join(":")
                                        : "-"}
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                <span
                                  onClick={() => {
                                    if (!leaves[day.date].closed) {
                                      setEditedLeave(leaves[day.date]);
                                      setIsEditingLeave(true);
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "8px"
                                  }}
                                  className="fw-semibold min-w-cell"
                                >
                                  {leaves[day.date].time
                                    ? leaves[day.date].time
                                        .split(":")
                                        .slice(0, 2)
                                        .join(":")
                                    : "-"}
                                </span>
                              )}
                              <div className="d-flex flex-column align-items-center">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-${leaves[day.date].id}`}
                                    >
                                      {leaves[day.date].closed
                                        ? t("leaveClosed")
                                        : leaves[day.date].leaveType ? leaves[day.date].leaveType.name : t("leaveTypeNotDefined")}
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={
                                      leaves[day.date].leaveType
                                        ? `${process.env.PUBLIC_URL}/${
                                            leaves[day.date].leaveType?.icon
                                          }_g.svg`
                                        : `${process.env.PUBLIC_URL}/not_found_g.svg`
                                    }
                                    alt="Leave Type"
                                    style={{
                                      width: leaves[day.date].leaveType?.icon ? "1rem" : "0.66rem",
                                      cursor: leaves[day.date].closed
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    className="m-1"
                                    onClick={() => {
                                      if (!leaves[day.date].closed) {
                                        handleShowModalLeaveType(
                                          leaves[day.date]
                                        );
                                      }
                                    }}
                                  />
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-${leaves[day.date].id}`}
                                    >
                                      {leaves[day.date].closed
                                        ? t("leaveClosed")
                                        : t("descriptionAndNotes")}
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/notes.svg`}
                                    alt="Notes"
                                    style={{
                                      width: "1rem",
                                      cursor: leaves[day.date].closed
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    className="m-1"
                                    onClick={() => {
                                      if (!leaves[day.date].closed) {
                                        handleShowModalDescNoteLeave(
                                          leaves[day.date]
                                        );
                                      }
                                    }}
                                  />
                                </OverlayTrigger>
                              </div>
                            </>
                          </div>
                        )
                      ) : isEditingLeave && editedLeave?.date === day.date ? (
                        <input
                          type="text"
                          value={editedLeave.time || ""}
                          onChange={(e) => {
                            handleLeaveChange({ date: day.date }, formatTime(e.target.value));
                          }}
                          onBlur={() => {
                            if (editedLeave.time) {
                              handleCreateLeave({
                                date: day.date,
                                time: editedLeave.time,
                              });
                            } else {
                              setIsEditingLeave(false);
                              setEditedLeave(null);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              if (editedLeave.time) {
                                handleCreateLeave({
                                  date: day.date,
                                  time: editedLeave.time,
                                });
                              }
                            }
                          }}
                          className="form-control cell-form mx-auto"
                          placeholder="HH:mm"
                          autoFocus
                        />
                      ) : (
                        <span
                          onClick={() => {
                            setEditedLeave({ date: day.date, time: "" });
                            setIsEditingLeave(true);
                          }}
                          style={{
                            cursor: "pointer",
                            marginRight: "8px"
                          }}
                          className="fw-semibold min-w-cell"
                        >
                          -
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
                {viewMode === "task"
                  ? sortedRender(groupedTasks)
                  : viewMode === "project"
                  ? sortedRender(groupedProjects)
                  : viewMode === "field"
                  ? sortedRender(groupedFields)
                  : viewMode === "customer"
                  ? sortedRender(groupedCustomers)
                  : null}
                {days.some((day) =>
                  timeslots.some((slot) => slot.date === day.date)
                ) && (
                  <>
                    <tr>
                      <td className="fw-semibold">
                        {t("totalWorkingTime")} {calculateTotalTime("work")})
                      </td>
                      {days.map((day) => {
                        const totalHoursForDay = sumTimes(
                          timeslots
                            .filter((slot) => slot.date === day.date)
                            .map((slot) => slot.time)
                        );
                        return (
                          <td key={day.date} className="text-center fw-semibold">
                            {totalHoursForDay !== "00:00"
                              ? totalHoursForDay
                              : "-"}
                          </td>
                        );
                      })}
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
            <div className="col-12 text-center">
              {timeslots.length === 0 && (
                <Button
                  className="green-text-button fw-semibold"
                  onClick={handleCopyAllActivities}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/copy.svg`}
                    alt="Copy timesheet"
                    style={{ width: "2.5rem", cursor: "pointer" }}
                    className="m-1"
                  />
                  {t("copyTimesheet")}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      {currentTimeslot && (
        <div>
          <ModalDescNote
            show={showModalDescNote}
            handleClose={handleCloseModalDescNoteTimeslot}
            item={currentTimeslot}
            onUpdate={handleUpdateTimeslot}
            isTimeslot={true}
          />
          <ModalFatt
            show={showModalFatt}
            handleClose={handleCloseModalFatt}
            timeslot={currentTimeslot}
            onUpdate={handleUpdateTimeslot}
          />
          <ModalPlaceTimeslot
            show={showModalPlace}
            handleClose={handleCloseModalPlace}
            timeslot={currentTimeslot}
            onUpdate={handleUpdateTimeslot}
          />
        </div>
      )}
      {currentLeave && (
        <div>
          <ModalLeavesType
            show={showModalLeaveType}
            handleClose={handleCloseModalLeaveType}
            leave={currentLeave}
            onUpdate={(updatedLeave) => {
              const updatedLeaves = {
                ...leaves,
                [currentLeave.date]: updatedLeave,
              };
              setLeaves(updatedLeaves);
            }}
          />
          <ModalDescNote
            show={showModalDescNote}
            handleClose={handleCloseModalDescNoteLeave}
            item={currentLeave}
            onUpdate={handleUpdateLeave}
            isTimeslot={false}
          />
        </div>
      )}
      <div>
        <ModalInsertTimeslot
          show={showModalInsertTimeslot}
          handleClose={handleCloseModalInsertTimeslot}
          startDate={startDate}
          endDate={endDate}
          timeslots={timeslots}
        />
        <ModalConfirmation
          show={showModalConfirmationApproval}
          onClose={handleCloseModalConfirmationApproval}
          onConfirm={handleConfirmSendApproval}
          title={t("confirmationSendingApproval")} 
          message={t("areYouSureSendingApproval")} 
          confirmText={t("yes")} 
          cancelText={t("cancel")} 
      />
      </div>
    </div>
  );
}

export default Timesheet;
